import React, { useState, useEffect, useCallback } from "react";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreIcon from '@mui/icons-material/Store';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import Tooltip from '@mui/material/Tooltip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FactoryIcon from '@mui/icons-material/Factory';
import ApprovalIcon from '@mui/icons-material/Approval';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';

import { Colors } from './lib/colors';
import DropDownLang from "./components/forms/DropDownSelect.tsx";
import MobileNav from "./components/nav/MobileNav";

import VendorDropDownSelect from "./components/forms/VendorDropDownSelect.tsx";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Routes from "./Routes";
import { AppContext } from "./lib/contextLib";
import { onError } from "./lib/errorLib";
import {
  getAllVendors,
  getVendor
} from "./services/vendors";
import LoaderButton from "./components/LoaderButton";

import {
  getAllUserPermissions,
  getLoggedInUserPermissions
} from "./services/user_permissions";
import {
  getAllBankAccounts,
} from "./services/bank_accounts";
import {
  getAllVendorDeposits,
  getAllDeposits
} from "./services/deposits";
import {
  getAllSpendingCategories,
} from "./services/spending_categories";
import {
  getAllVendorSpendings,
} from "./services/spendings";
import {
  getAllUsers,
  getUser,
  updateUser
} from "./services/users";
import {
  getAllUserRoles
} from "./services/user_roles";
import {
  getAllProductCats
} from "./services/product_categories";
import {
  getAllProducts,
  getVendorProducts,
  getAllAvailableVendorProducts
} from "./services/products";
import {
  getAllCompanies
} from "./services/companies";

import {
  getCompany
} from "./services/companies";

import {
  getPendingTransferProducts,
  getMyTransferProducts,
  getVendorsTransferProducts
} from "./services/products_transfer";

import {
  getSetting
} from "./services/settings";
import {
  getVendorOrders
} from "./services/orders";
import {
  getAllCustomers
} from "./services/customers";
import {
  getAllSuppliers
} from "./services/suppliers";
import {
  getAllProductsReceived
} from "./services/products_received";
import {
  getAllApprovals,
  getVendorPendingDispatches
} from "./services/approvals";

import { translator, Text } from './services/translator';
import CompanyLogo from "./components/cards/CompanyLogo";
import LoadingDialog from "./components/dialogs/Loading";
import FeedBack from "./components/shared/FeedBack";
import "./App.css";
import { Toaster } from 'react-hot-toast';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function App() {
  const history = useHistory();
  const [lang, setLang] = useState('fr');
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackType, setFeedbackType] = useState('');
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [store, setStore] = useState({
    isLoading: false,
    users: [],
    vendors: [],
    roles: []
  });

  const [adminUsers, setAdminUsers] = useState(false);
  const [isSupperAdmin, setIsSupperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selected, setSelected] = useState('');
  const [taxRate, setTaxRate] = useState(18);
  const [approvalsCount, setApprovalsCount] = useState(1);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [user, setUser] = useState({});
  const [userPermissions, setUserPermissions] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [spendings, setVendorSpendings] = useState([]);
  const [spendingCategories, setSpendingCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [settings, setSettings] = useState({});
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState({});
  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [vendorsAvailableProducts, setVendorsAvailableProducts] = useState([]);
  const [pendingProducts, setPendingProducts] = useState([]);
  //My requested products
  const [myTransferProducts, setMyTransferProducts] = useState([]);
  //vendor requested products
  const [vendorsTransferProducts, setVendorsTransferProducts] = useState([]);
  //pending product transfer to approve
  const [pendingTransferProducts, setPendingTransferProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersTodayVendor, setOrdersTodayVendor] = useState([]);
  const [cashierData, setCashierData] = useState([]);
  const [vendorOrderStatistics, setVendorOrderStatistics] = useState({});
  const [customers, setCustomers] = useState([]);
  const [company, setCompany] = useState({});
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [companyYear, setCompanyYear] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [receiveProducts, setReceiveProducts] = useState({});
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [inventoryCostChangeMethods] = useState([
    { value: 'FIFO', name: 'First-In, First-Out (FIFO)' },
    // { value: 'LIFO', name: 'Last-In, First-Out (LIFO)' },
    { value: 'AVG', name: 'Average Cost' }
  ]);//First-In, First-Out (FIFO) || Last-In, First-Out (LIFO) || Average Cost

  const [transStatuses] = useState([
    { value: 'p', name: 'Pending' },
    { value: 'a', name: 'Approved' },
    { value: 'd', name: 'Declined' }
  ]);

  const [spendingTypes] = useState([
    { value: 'vendor', name: 'Vendor Expenses' },
    { value: 'vehicle', name: 'Vehicle Expenses' },
    { value: 'loan', name: 'Loan' }
  ]);
  const [languages] = useState([
    { value: 'fr', name: 'French' },
    { value: 'en', name: 'English' }
  ]);

  const [yearsList] = useState([
    '2022',
    '2023',
    '2024',
    '2025'
  ]);
  const [productQuantityTypes] = useState([
    'm2',
    'm',
    'ctns',
    'pcs'
  ]);
  const [productLengthUnits] = useState([
    'm',
    'cm',
    'mm',
    'in'
  ]);
  const [productDispatchStatus] = useState([
    { value: 'processing', name: 'Processing' },
    { value: 'shipped', name: 'Shipped' }
  ]);
  const [orderStatus] = useState([
    'Processing',
    'Shipped',
    'Received'
  ]);

  const [orderStateFilters] = useState([
    { value: 'all', name: 'All' },
    { value: 'draft', name: 'Draft' },
    { value: 'placed', name: 'Placed' },
    { value: 'closed', name: 'Closed' },
    { value: 'cancelled', name: 'Cancelled' },
    { value: 'hold', name: 'Hold' }
  ]);
  const [spendingTypeFilters] = useState([
    { value: 'all', name: 'All' },
    { value: 'loan_sp', name: <Text>loan</Text> },
    { value: 'vendor_sp', name: <Text>vendor</Text> },
    { value: 'vehicle_sp', name: <Text>vehicle</Text> }
  ]);
  const [shippingMethods] = useState([
    'Delivery',
    'Pick Up',
    'Other'
  ]);
  const [paymentMethods] = useState([
    'Bank Deposit',
    'Cash',
    'Debit/Credit',
    'Mobile Transfer',
    'Other'
  ]);

  const [states] = useState([
    'Dakar',
    'Diourbel',
    'Fatick',
    'Kaffrine',
    'Kaolack',
    'Kédougou',
    'Kolda',
    'Louga',
    'Matam',
    'Saint-Louis',
    'Sédhiou',
    'Tambacounda',
    'Thiès',
    'Ziguinchor'
  ]);
  const [actions] = useState({

    onGetData: async () => {
      setIsLoading(true);
      await onLoad();
    },
    onGetUsers: (company_id) => {
      getUsers(company_id).then((data) => {
        setUsers(data);
        if (data && data.length !== undefined) {
          let activeUsers = data.filter(item => item.active === true);
          setActiveUsers(activeUsers);
          setIsLoading(false);
        }
      });
    },

    onGetVendors: (company_id) => {
      getVendors(company_id).then((data) => {
        setVendors(data);
        setIsLoading(false);
      });
    },
    onGetUserRoles: (company_id) => {
      getUserRoles(company_id).then((data) => {
        setRoles(data);
        setIsLoading(false);
      });
    },
    onGetPermissions: () => {
      getPermissions().then((data) => {
        setPermissions(data);
        setIsLoading(false);
      });
    },

    onGetProductCats: (company_id) => {
      getProductCats(company_id).then((data) => {
        setProductCategories(data);
        setIsLoading(false);
      });
    },
    onGetProducts: (vendorId, company_id) => {
      getProducts(vendorId, company_id).then((data) => {
        setProducts(data);
        setIsLoading(false);
      });
    },
    onGetAvailableVendorsProducts: (company_id) => {
      getAvailableVendorsProducts(company_id).then((data) => {
        setIsLoading(false);
      });
    },
    onGetPendingDispatch: (vendorId) => {
      getPendingDispatch(vendorId).then(() => {
        setIsLoading(false);
      });
    },
    onGetOrders: (vendorId) => {
      getOrders(vendorId).then((data) => {

        if (data != null) {
          setOrders(data.orders);
          setVendorOrderStatistics(data.statistics);
          setCashierData(data.cashierData);
          setDeposits(data.cashierData.vendorDeposits);
          setVendorSpendings(data.cashierData.vendorSpendings);

        }
        setIsLoading(false);
      });
    },
    onGetCustomers: (company_id) => {
      getCustomers(company_id).then((data) => {
        setCustomers(data);
        setIsLoading(false);
      });
    },
    onGetCompanies: () => {
      getCompanies().then((data) => {
        setCompanies(data);
        setIsLoading(false);
      });
    },
    onGetCompany: (id) => {
      getCompanyRecord(id).then((data) => {
        setCompany(data);
        //setIsLoading(false);
      });
    },
    onGetSuppliers: (company_id) => {
      getSuppliers(company_id).then(() => {
        setIsLoading(false);
      });
    },

    onGetReceiveProducts: (company_id) => {
      getReceiveProducts(company_id).then(() => {
        setIsLoading(false);
      });
    },

    onGetProductTransfers: (id, company_id) => {
      getProductTransfers(id, company_id).then(() => {
        setIsLoading(false);
      });
    },
    onGetSettings: () => {
      getSettings().then((data) => {
        setSettings(data);
        setIsLoading(false);
      });
    },
    onSetSelected: (page) => {
      setSelected(page);
    },
    onSetCompanyId: (id) => {
      setCompanyId(id);
    },
    onSetIsLoading: (loading) => {
      setIsLoading(loading);
    },
    onSetFeedback: (active, message, type) => {
      setShowFeedback(active);
      setFeedbackMessage(message);
      setFeedbackType(type);

      setTimeout(function () {
        setShowFeedback(false);
      }, 5000);

    },
    onGetBankAccounts: (company_id) => {
      getBankAccounts(company_id).then((data) => {
        setBankAccounts(data);
        setIsLoading(false);
      });
    },
    onGetDeposits: (company_id) => {
      getDeposits(company_id).then((data) => {
        setDeposits(data);
        setIsLoading(false);
      });
    },
    onGetVendorSpendings: (vendor_id) => {
      getVendorSpendings(vendor_id).then((data) => {
        setVendorSpendings(data);
        setIsLoading(false);
      });
    },
    onGetSpendingCategories: (company_id) => {
      getSpendingCats(company_id).then((data) => {
        setSpendingCategories(data);
        setIsLoading(false);
      });
    },
    onGetApprovals: (company_id, current_year) => {
      getAllPendingApprovals(company_id, current_year).then((data) => {
        let deposits = [];

        if (data.length !== undefined && data.length > 0) {
          data.forEach(element => {
            if (element.approvalType === 'deposits') {
              deposits.push(element);
            }
          });
        }

        setPendingApprovals(deposits);
        setIsLoading(false);
      });
    },

    onHandleLogout: () => {
      handleLogout();
    },

    //Pages Data calls
    _onGetDashData: async (vendorId, company_id) => {//All data for Dashboard Page
      await getAvailableVendorsProducts(company_id);
      await getReceiveProducts(company_id);
      await getPendingDispatch(vendorId);
      await getProductTransfers(vendorId, company_id);
      setIsLoading(false);
      let newOrders = await getOrders(vendorId);
      if (newOrders != null) {
        setOrders(newOrders.orders);
        setVendorOrderStatistics(newOrders.statistics);
        setCashierData(newOrders.cashierData);
        setDeposits(newOrders.cashierData.vendorDeposits);
        setVendorSpendings(newOrders.cashierData.vendorSpendings);
        setOrdersTodayVendor(newOrders.ordersToday);
      }
      setIsLoading(false);
    },
    onGetCashierData: async (vendorId) => {//All data for Cashier Page
      await actions.onGetOrders(vendorId);
      getApprovalCounts();
    },
    _onGetProductsData: async (vendorId, company_id) => {//All data for Products Page
      await actions.onGetProducts(vendorId, company_id);
      await getAvailableVendorsProducts(company_id);
      await actions.onGetProductCats(company_id);
    },
    _onGetVendorsData: async (company_id) => {//All data for Vendors Page
      await actions.onGetVendors(company_id);
    },
    _onGetCustomersData: async (company_id) => {//All data for Customers Page
      await actions.onGetCustomers(company_id);
    },
    _onGetSuppliersData: async (company_id) => {//All data for Suppliers Page
      await actions.onGetSuppliers(company_id);
    },
    _onGetUsersData: async (company_id) => {//All data for Users Page
      await actions.onGetUsers(company_id);
      await actions.onGetPermissions();
    },
    _onGetUserRolesData: async (company_id) => {//All data for User Roles Page
      await actions.onGetUserRoles(company_id);
    },
    _onGetSettingsData: async () => {//All data for User Roles Page
      await actions.onGetSettings();
      await actions.onGetPermissions();
      //await actions.onGetBankAccounts();
      //await actions.onGetSpendingCategories();
    },

    _onGetAdminDashData: async () => {//All data for User Roles Page
      await actions.onGetSettings();
      await actions.onGetPermissions();
      await actions.onGetCompanies();
      //await actions.onGetBankAccounts();
      //await actions.onGetSpendingCategories();
    },

    _onGetPendingApprovalsData: async (company_id, current_year) => {//All data for Approval Page
      await actions.onGetApprovals(company_id, current_year);
      // await actions.onGetPendingDispatch(vendor.vendorId)
    },

  });

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(async () => {//Set needed values --first load
    onLoad();
  }, []);

  // useEffect(async () => {//Watch for auth changes --Second load

  //   if (isAuthenticated) {//
  //     if (!isSupperAdmin) {
  //       await onLoad();
  //     } else {//Get admin data
  //       await actions._onGetAdminDashData();
  //     }
  //   }

  // }, [isAuthenticated, isSupperAdmin]);

  async function onLoad() {//Load Company Data --Second Load
    try {
      setIsLoading(true);
      await Auth.currentSession();
      let auth = await Auth.currentAuthenticatedUser();

      if (auth.attributes['custom:isAdmin'] !== undefined && auth.attributes['custom:isAdmin'] == '1') {//Admin User
        setIsSupperAdmin(true);
        userHasAuthenticated(true);
        await actions._onGetAdminDashData();
      } else {//Company User
        userHasAuthenticated(true);
        setCompanyId(auth.attributes['custom:companyId']);
        let comp = await getCompanyRecord(auth.attributes['custom:companyId']);
        setCompanyId(comp.companyId);
        console.log('comp', comp);
        setCompany(comp);
        setCompanyYear(comp.currentYear);
        getAuthUser(auth.attributes['custom:userId']);
        await loadData(auth.attributes['custom:vendorId'], auth.attributes['custom:companyId'], comp.currentYear);
      }

    } catch (e) {
      setIsLoading(false);
      //handleLogout();
      if (e !== 'No current user') {
        // onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function loadData(vendor_id, company_id, current_year) {//Final Load
    try {
      setIsLoading(true);
      let newProductCats = await getProductCats(company_id);
      let newVendors = await getVendors(company_id);

      setVendors(newVendors);
      setProductCategories(newProductCats);

      await actions._onGetDashData(vendor_id, company_id);
      //setIsLoading(false);

      //Continue loading data
      actions.onGetSpendingCategories(company_id);
      actions._onGetPendingApprovalsData(company_id, current_year);
      actions._onGetSuppliersData(company_id);
      actions._onGetProductsData(vendor_id, company_id);
      actions._onGetCustomersData(company_id);
      actions._onGetUsersData(company_id);
      actions._onGetUserRolesData(company_id);

    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/a_portal/login");
  }

  useEffect(() => {
    getApprovalCounts();
  }, [
    pendingApprovals,
    pendingProducts,
    myTransferProducts,
    vendorsTransferProducts,
    pendingTransferProducts
  ]);

  //Set counts for Approvals Page
  const getApprovalCounts = async () => {
    let count = 0;

    if (userPermissions.admin === true || userPermissions.bank_deposit_approval === true) {
      count += pendingApprovals.length; //Pending deposits
    }
    if (vendor.isMainVendor != true) {
      count += pendingProducts.length; //Dispatched products to vendors
    }
    if (vendor.isMainVendor != true) {
      count += vendorsTransferProducts.length; //Requests from other vendors
    }
    if (vendor.isMainVendor === true) {
      count += pendingTransferProducts.length; //transfers that need confirmation from Main vendor
    }
    if (userPermissions.admin === true || userPermissions.receive_products === true || vendor.isMainVendor === true) {

      if (receiveProducts.pendingReceives !== undefined && receiveProducts.pendingReceives.length !== undefined) {
        count += receiveProducts.pendingReceives.length;//Vendors pending product dispatch
      }
      // count += receiveProducts.pendingReceives.length || 0;//Vendors pending product dispatch
    }

    setApprovalsCount(count);
  };

  const getVendors = async (company_id) => {
    try {

      let response = await getAllVendors(company_id);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getUsers = async (company_id) => {//Get all users from the system
    try {

      let response = await getAllUsers(company_id);//co

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getAuthUser = async (id) => {//Auth user information
    try {

      if (!isSupperAdmin) {
        let user = await getUser(id);

        if (user.active != true) {
          alert('Sorry, you do not have access to use the system.');
          handleLogout();
          return;
        }
        await getUserPermissions(user.userPermissions || []);

        setUser(user);
        setLang(user.siteLanguage);
        if (user.dashboardVendorId != undefined && user.dashboardVendorId != null) {
          let vendor = await getVendor(user.dashboardVendorId);
          setVendor(vendor);
        } else {
          if (user.vendorId) {
            let vendor = await getVendor(user.vendorId);
            setVendor(vendor);
          }
        }
      }

    } catch (err) {
      console.log("Err getAuthUser(): " + err);
    }

  };

  const getPendingDispatch = async (id) => {
    try {

      let response = await getVendorPendingDispatches(id);
      setPendingProducts(response);

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getAvailableVendorsProducts = async (company_id) => {
    try {

      let response = await getAllAvailableVendorProducts(company_id);

      setVendorsAvailableProducts(response);

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getUserRoles = async (company_id) => {
    try {

      let response = await getAllUserRoles(company_id);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getPermissions = async () => {
    try {

      let response = await getAllUserPermissions();
      return response;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getBankAccounts = async (company_id) => {
    try {

      let response = await getAllBankAccounts(company_id);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }
  };

  const getCompanies = async () => {
    try {

      let response = await getAllCompanies();

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }
  };

  const getDeposits = async (id) => {
    try {
      let vendorId = null;
      if (id !== undefined) {
        vendorId = id;
      } else {
        vendorId = vendor.vendorId;
      }

      let response = await getAllVendorDeposits(vendorId);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }
  };

  const getVendorSpendings = async (id) => {
    try {
      let vendorId = null;
      if (id !== undefined) {
        vendorId = id;
      } else {
        vendorId = vendor.vendorId;
      }

      let response = await getAllVendorSpendings(vendorId);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }
  };

  const getSpendingCats = async (company_id) => {
    try {

      let response = await getAllSpendingCategories(company_id);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getProductCats = async (company_id) => {
    try {

      let response = await getAllProductCats(company_id);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getProducts = async (vendorId, company_id) => {
    try {
      
      
      let response = await getVendorProducts(vendorId);
      // let newResponse = [];
      // response.filter(item => item.active == true)
      //   .map(item => {
      //     newResponse.push(item);
      //   });

      let allProducts = await getAllProducts(company_id);
      // let newAllProducts = [];
      // allProducts.filter(item => item.active == true)
      //   .map(item => {
      //     newAllProducts.push(item);
      //   });
      // setAllProducts(newAllProducts);
      setAllProducts(allProducts);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getOrders = async (vendorId) => {
    try {
      let response = null;
      response = await getVendorOrders(vendorId);

      return response;
    } catch (err) {
      console.log("Err: " + err);
    }
  };

  const getCustomers = async (company_id) => {
    try {

      let response = await getAllCustomers(company_id);
      let newCustomers = [];

      response.filter(item => item.fullName != null && item.active === true)
        .map(item => {
          newCustomers.push(item);
        });
      setFilteredCustomers(newCustomers);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getSettings = async () => {
    try {

      let response = await getSetting();

      if (response.active) {
        return response;
      }

      return {};

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getUserPermissions = async (userPermissionsData) => {
    try {
      let response = await getLoggedInUserPermissions(userPermissionsData);

      setUserPermissions(response);

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getProductTransfers = async (id, company_id) => {
    try {
      let processorTransfers = await getPendingTransferProducts(id, company_id);
      let processorRequester = await getMyTransferProducts(id, company_id);
      let processorSender = await getVendorsTransferProducts(id);

      setMyTransferProducts(processorRequester);
      setVendorsTransferProducts(processorSender);
      setPendingTransferProducts(processorTransfers);

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getSuppliers = async (company_id) => {
    try {
      let suppliers = await getAllSuppliers(company_id);

      setSuppliers(suppliers);

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getCompanyRecord = async (company_id) => {
    try {
      let response = await getCompany(company_id);

      return response;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getReceiveProducts = async (company_id) => {
    try {
      let products = await getAllProductsReceived(company_id);

      setReceiveProducts(products);

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const getAllPendingApprovals = async (company_id, current_year) => {
    try {
      let approvals = await getAllApprovals(company_id, current_year);

      return approvals;

    } catch (err) {
      console.log("Err: " + err);
    }

  };

  const changeLanguage = async (lg) => {
    try {
      setLang(lg);
      await updateUser(user.userId, { ...user, siteLanguage: lg });

    } catch (err) {
      console.log("Err: " + err);
    }
  };

  const changeVendorDash = async (val) => {
    try {

      let response = await updateUser(user.userId, { ...user, dashboardVendorId: val });

      if (response.status) {
        window.location.reload(false);
      }
    } catch (err) {
      console.log("Err: " + err);
    }
  };

  const refreshData = async (page) => {//Used for refreshing pages

    try {
      setIsRefreshing(true);
      if (page === 'home') {
        await actions._onGetDashData(vendor.vendorId, companyId);
      } else if (page === 'cashier') {
        await actions.onGetCashierData(vendor.vendorId);
      } else if (page === 'orders') {
        await actions.onGetOrders(vendor.vendorId);
      } else if (page === 'products') {
        await actions._onGetProductsData(vendor.vendorId, companyId);
      } else if (page === 'vendors') {
        await actions._onGetVendorsData(companyId);
      } else if (page === 'customers') {
        await actions._onGetCustomersData(companyId);
      } else if (page === 'suppliers') {
        await actions._onGetSuppliersData(companyId);
      } else if (page === 'users') {
        await actions._onGetUsersData(companyId);
      } else if (page === 'user_roles') {
        await actions._onGetUserRolesData(companyId);
      } else if (page === 'settings') {
        await actions._onGetSettingsData();
      } else if (page === 'pending_approvals') {
        await actions._onGetPendingApprovalsData(companyId, companyYear);
      }
      setIsRefreshing(false);

    } catch (err) {
      console.log("Err: " + err);
    }
  };

  useEffect(() => {
    let newMenuItems = [];
    let object = {
      name: <Text>Dashboard</Text>,
      value: 'home',
      url: '/a_portal'
    }
    newMenuItems.push(object);

    if (userPermissions.admin || userPermissions.cashier_read || userPermissions.cashier_write) {
      let object = {
        name: <Text>Cashier</Text>,
        value: 'cashier',
        url: '/a_portal/cashier'
      }
      newMenuItems.push(object);
    }

    if (userPermissions.admin || userPermissions.orders_read || userPermissions.orders_write) {
      let object = {
        name: <Text>Orders</Text>,
        value: 'orders',
        url: '/a_portal/orders'
      }
      newMenuItems.push(object);
    }

    if (userPermissions.admin || userPermissions.products_read || userPermissions.products_write) {
      let object = {
        name: <Text>Products</Text>,
        value: 'products',
        url: '/a_portal/products'
      }
      newMenuItems.push(object);
    }

    if (userPermissions.admin || userPermissions.approval_access) {
      let object = {
        name: <Text>Pending Approvals</Text>,
        value: 'pending_approvals',
        url: '/a_portal/pending_approvals'
      }
      newMenuItems.push(object);
    }

    if (userPermissions.admin || userPermissions.vendors_read || userPermissions.vendors_write) {
      let object = {
        name: <Text>Vendors</Text>,
        value: 'vendors',
        url: '/a_portal/vendors'
      }
      newMenuItems.push(object);
    }

    if (userPermissions.admin || userPermissions.customers_read || userPermissions.customers_write) {
      let object = {
        name: <Text>Customers</Text>,
        value: 'customers',
        url: '/a_portal/customers'
      }
      newMenuItems.push(object);
    }

    if (userPermissions.admin || userPermissions.suppliers_read || userPermissions.suppliers_write) {
      let object = {
        name: <Text>Suppliers</Text>,
        value: 'suppliers',
        url: '/a_portal/suppliers'
      }
      newMenuItems.push(object);
    }

    if (userPermissions.admin || userPermissions.users_read || userPermissions.users_write) {
      let object = {
        name: <Text>Users</Text>,
        value: 'users',
        url: '/a_portal/users'
      }
      newMenuItems.push(object);
    }

    if (userPermissions.admin || userPermissions.user_roles_read || userPermissions.user_roles_write) {
      let object = {
        name: <Text>User Roles</Text>,
        value: 'user_roles',
        url: '/a_portal/user_roles'
      }
      newMenuItems.push(object);
    }

    if (userPermissions.admin || userPermissions.settings_roles_read || userPermissions.settings_write) {
      let object = {
        name: <Text>Settings</Text>,
        value: 'settings',
        url: '/a_portal/settings'
      }
      newMenuItems.push(object);
    }

    setMenuItems(newMenuItems);
    forceUpdate();

  }, [userPermissions]);

  return (
    !isAuthenticating && (
      <div className="App py-3">
        <Toaster />
        <translator.TranslationProvider activeLang={lang}>
          <Box sx={{ display: 'flex' }} >
            <CssBaseline />

            {isAuthenticated &&
              <AppBar position="fixed" open={open} style={{ background: Colors.navBlue }}>

                <Toolbar>
                  <MobileNav
                    menuItems={menuItems}
                    approvalsCount={approvalsCount}
                    selected={selected}
                    onHandleLogout={handleLogout}
                  />
                  <Grid
                    container
                    spacing={1}
                    //sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                    sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}
                  >

                    <Grid item xs={12} sm={6} md={6}>
                      <Grid
                        container
                        spacing={2}
                      >

                        <Grid item xs={12} sm={1} md={1} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                              marginRight: 5,
                              // marginTop: 2,
                              ...(open && { display: 'none' }),
                            }}
                          >
                            <MenuIcon />
                          </IconButton>

                        </Grid>
                        <Grid item xs={12} sm={10} md={10}>
                          <div style={{
                            flexDirection: "row",
                            justifyContent: "flex-start"
                          }}>
                            <CompanyLogo
                              attachment={company.companyLogo}
                            />
                            {/* <img
                              width="250"
                              src={`${'/logo3.png'}?w=164&h=164&fit=crop&auto=format`}
                              srcSet={`${'/logo3.png'}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                              alt={'title'}
                              loading="lazy"
                            /> */}

                            <Typography variant="h6" noWrap component="div" >
                              {!isSupperAdmin ? <LinkContainer to={'/a_portal/vendors/' + vendor.vendorId || ''} >
                                <span>
                                  {vendor.vendorName} | {user.firstName} {user.lastName}
                                </span>
                              </LinkContainer> : <span>ADMIN PORTAL</span>}
                              <span>{userPermissions.vendor_switch === true && (user.dashboardVendorId !== null && user.dashboardVendorId !== user.vendorId) &&
                                <LoaderButton
                                  style={{
                                    marginLeft: 10,
                                    marginBottom: 5
                                  }}
                                  type="submit"
                                  size="sm"
                                  variant="primary"
                                  isLoading={isRefreshing}
                                  onClick={() => {
                                    changeVendorDash(null);
                                  }}
                                >
                                  <Text>BACK TO MY STORE</Text>
                                </LoaderButton>

                              }
                              </span>
                            </Typography>

                          </div>
                        </Grid>


                      </Grid>

                    </Grid>

                    <Grid item xs={12} sm={6} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'right',
                        }}
                      >
                        <Grid item xs={12} sm={3} md={3}
                          style={{
                            //marginRight: 5
                          }}
                        >
                          {userPermissions.vendor_switch &&
                            <VendorDropDownSelect
                              val={user.dashboardVendorId || user.vendorId}
                              rows={vendors}
                              title={<Text>Switch Store</Text>}
                              onChange={changeVendorDash}
                            />
                          }

                        </Grid>

                        <Grid item xs={12} sm={3} md={3}>
                          <DropDownLang
                            lg={lang}
                            rows={languages}
                            title={''}
                            onChange={changeLanguage}
                          />
                        </Grid>

                        {/* <Grid item xs={12} sm={2} md={2} >
                          <LoaderButton
                            style={{
                              marginTop: 20,
                              marginLeft: 10,
                            }}
                            type="submit"
                            size="sm"
                            variant="primary"
                            isLoading={isRefreshing}
                            onClick={() => {
                              refreshData(selected);
                            }}
                          >
                            <Text>REFRESH</Text>
                          </LoaderButton>
                        </Grid> */}
                      </Grid>
                    </Grid>

                  </Grid>

                </Toolbar>
              </AppBar>
            }
            {isAuthenticated &&

              <Drawer variant="permanent" open={open} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                <DrawerHeader style={{ background: '#35467E' }} >

                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>

                </DrawerHeader>
                <Divider />
                <List style={{ marginTop: 5, }} >

                  {menuItems.map(({ name, value, url }, index) => (
                    <div key={value + index}>
                      <LinkContainer to={url} >
                        <Tooltip
                          placement="top"
                          arrow
                          title={name}
                        >
                          <ListItemButton
                            selected={selected == value ? true : false}

                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >

                              {value === 'home' ? <HomeIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                value === 'products' ? <LocalOfferIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                  value === 'orders' ? <ShoppingBasketIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                    value === 'cashier' ? <PointOfSaleIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                      value === 'customers' ? <GroupIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                        value === 'vendors' ? <StoreIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                          value === 'users' ? <SupervisedUserCircleIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                            value === 'user_roles' ? <AccessibilityIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                              value === 'settings' ? <SettingsApplicationsIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                                value === 'suppliers' ? <FactoryIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> :
                                                  value === 'pending_approvals' ? <Badge badgeContent={approvalsCount} color="error"> <ApprovalIcon style={{ color: selected == value ? Colors.navBlue : Colors.gold }} /> </Badge> : null
                              }

                            </ListItemIcon>
                            <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                        </Tooltip>
                      </LinkContainer>
                      {value == 'user_roles' && <Divider />}
                    </div>
                  ))}

                  <Tooltip
                    placement="top"
                    arrow
                    title={<Text>Logout</Text>}
                  >
                    <ListItemButton
                      key={'logout'}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      onClick={handleLogout}
                    >

                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >

                        <LogoutIcon style={{ color: Colors.error }} />

                      </ListItemIcon>

                      <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />

                    </ListItemButton>
                  </Tooltip>
                </List>
              </Drawer>
            }
            {isLoading ? <LoadingDialog /> : <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <DrawerHeader />
              {/* <div style={{
                    flexDirection: "row",
                    justifyContent: "flex-end"
                  }}>
                    <Typography variant="h4" noWrap component="div" >
                      Dashboard
                    </Typography>
                  </div> */}

              <AppContext.Provider
                value={{
                  isAuthenticated,
                  userHasAuthenticated,
                  spendingTypes,
                  taxRate,
                  yearsList,
                  company,
                  companies,
                  companyId,
                  companyYear,
                  roles,
                  receiveProducts,
                  vendors,
                  vendor,
                  activeUsers,
                  users,
                  user,
                  userPermissions,
                  menuItems,
                  spendingCategories,
                  spendings,
                  bankAccounts,
                  deposits,
                  pendingApprovals,
                  approvalsCount,
                  paymentMethods,
                  transStatuses,
                  permissions,
                  myTransferProducts,
                  vendorsTransferProducts,
                  pendingTransferProducts,
                  productCategories,
                  products,
                  allProducts,
                  pendingProducts,
                  productQuantityTypes,
                  productLengthUnits,
                  productDispatchStatus,
                  vendorsAvailableProducts,
                  orderStatus,
                  orderStateFilters,
                  spendingTypeFilters,
                  orders,
                  ordersTodayVendor,
                  cashierData,
                  vendorOrderStatistics,
                  customers,
                  filteredCustomers,
                  suppliers,
                  shippingMethods,
                  paymentMethods,
                  inventoryCostChangeMethods,
                  states,
                  settings,
                  isAdmin,
                  isSupperAdmin,
                  selected,
                  store,
                  actions
                }}
              >
                <Routes />
              </AppContext.Provider>

            </Box>}

          </Box>
          {showFeedback &&
            <FeedBack
              message={feedbackMessage}
              type={feedbackType}
            />
          }
        </translator.TranslationProvider>
      </div>
    )
  );
}

export default App;




